<template>
  <!-- 县市区赛事数量排名 -->
  <div class="baixing darkBack">
    <div class="baixingnav">
      <div>健身房运动时长总排名</div>
      <div>
        <!-- <span class="selecte" id="selected" @click="selectedhandle">月</span> -->
        <span class="anselecte" @click="anselectedhandle">月</span>
        <span class="anselecte" @click="anselectedhandle">年</span>
        <span class="anselecte" @click="anselectedhandle">总</span>
      </div>
    </div>
    <div id="ancent1"></div>
    <div id="jianoption1"></div>
  </div>
</template>

<script>
import bus from "/src/assets/js/bus.js";
export default {
  data() {
    return {
      sport: {
        data: [
          "1 城南街道高立社区智慧化健身房",
          "2 鉴湖街道玉屏村智慧化健身房",
          "3 鉴湖街道丰乐村智慧化健身房",
          "4 绍兴市柯桥区福全街道健身房",
          "5 灵芝街道大树港居智慧化健身房",
          "6 东浦街道王城寺村智慧化健身房",
          "7 马山街道安城社区智慧化健身房",
          "8 孙端街道红鲍村智慧化健身房",
          "9 绍兴市柯桥区漓渚镇九板桥",
          "10 孙端街道红鲍村智慧化健身房",
        ],
        number: ["100", "92", "84", "76", "62", "54", "46", "38", "30", "22"],
        max: 9,
        color1: "#7EA7E2", // 左侧字体颜色
        color2: "#2D94D0", // 渐变初始颜色
        color3: "#3AE6CE", // 渐变末尾颜色
        color4: "#3AE5CE", // 柱状图上字体的颜色
      },

      // sport1: {
      //    data:['39 灵芝街道大叔港智慧化健身房'],
      //     number:['65'],
      //     max:"0",
      //     color1:"#EFDB29",
      //     color2:"#E5813A",
      //     color3:"#EFDC29",
      //     color4:"#EFDB29",
      // },
    };
  },
  methods: {
    anselectedhandle(e) {
      // 点击切换样式
      // $('.selecte').class('background', "#000000")
      $(".anselecte").css({
        "background-color": "#00000000",
        color: "#838181",
      });
      e.target.style.background = "#7EA7E2";
      e.target.style.color = "#000000";

      if (e.target.innerHTML == "月") this.getTimeRank("month");
      else if (e.target.innerHTML == "年") this.getTimeRank("year");
      else this.getTimeRank();
    },
    fitness(id, number) {
      // let canye = this.$echarts.init(document.getElementById(id));
      let canye = this.$echarts.getInstanceByDom(document.getElementById(id));
      if (canye == null) {
        // 如果不存在，就进行初始化
        canye = this.$echarts.init(document.getElementById(id));
      }
      // console.log(number)
      let option = {
        xAxis: {
          // max: 'dataMax',
          show: true,
          type: "value",
          axisLine: {
            //x轴坐标轴
            show: false,
          },
          axisLabel: {
            show: false,
          },

          axisTick: {
            //x轴刻度线
            show: false,
          },
          //     splitLine: {            // 网格线的颜色
          //         show: true,
          //         lineStyle:{
          //             color: ['#2D94D040'],
          //             width: 1,
          //             type: 'solid',
          //         }
          // 　　}
          splitLine: {
            // 网格线的颜色
            show: false,
          },
        },
        grid: {
          // 间距
          left: "-5%",
          top: "2%",
          bottom: "1%",
          containLabel: true,
        },
        yAxis: {
          type: "category",
          data: number.data,
          inverse: true, // 上下反向
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: number.max, // y轴显示显示最大的条数
          axisLine: {
            // y轴左侧的样式
            show: true,
            lineStyle: {
              color: "#2D94D0",
            },
          },
          splitLine: { show: false },
          axisTick: {
            // y轴刻度线
            show: false,
            alignWithLabel: true,
          },
          splitArea: { show: false },
          axisLabel: {
            // y轴字体的颜色
            margin: 150, //刻度标签与轴线之间的距离
            interval: 0, //横轴信息全部显示
            textStyle: {
              show: true,
              fontFamily: "微软雅黑",
              color: number.color1,
              fontSize: "0.13rem",
              align: "left",
            },
          },
        },
        series: [
          {
            // realtimeSort: true,
            // name: 'X',
            type: "bar",
            barWidth: "40%", // 柱子的宽度
            data: number.number,
            itemStyle: {
              normal: {
                color: this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: number.color2, // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: number.color3, // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            label: {
              formatter: "{c}",
              show: true,
              position: "right",
              valueAnimation: true,
              color: number.color4, // 柱状图上字体的颜色
              fontSize: "0.13rem",
            },
          },
        ],
        legend: {
          show: true,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
      };

      canye.setOption(option);
      //图表自动缩放
      window.addEventListener("resize", function() {
        canye.resize();
      });
    },
    // 获取健身时长排名
    getTimeRank(type) {
      let that = this;
      let data;
      // if(type == undefined){
      //     data = {
      //         type:"",
      //         businessid:window.jianshenfangData.id
      //     }
      // }else{
      //     data = {
      //         type:type,
      //         businessid:window.jianshenfangData.id
      //     }
      // }
      // console.log(data)
      let url =
        window.appHost +
        `/api/v1/screen/Venue/durationRankingDate?businessid=${window.jianshenfangData.id}&type=${type}`;
      // console.log(url)
      let result = window.ajaxRequest(url, JSON.stringify(data));
      // console.log(result);

      this.sport.data = [];
      this.sport.number = [];
      result.data.forEach((item, index) => {
        if (index == 0) this.sport.data.push(item.title);
        else this.sport.data.push(index + "   " + item.title);
        this.sport.number.push(item.duration);
      });
      // console.log(this.sport)
      this.fitness("jianoption1", this.sport);
    },
  },
  mounted() {
    // this.fitness("jianoption1", this.sport);
    // this.fitness("ancent1", this.sport1);
    // console.log(window.jianshenfangData)
    this.getTimeRank("month");
  },
};
</script>

<style scoped>
.baixing {
  width: 5.94rem;
  height: 3.7rem;
  font-size: 0.18rem;
  color: #fff;
  padding: 0.2rem;
  position: relative;
}
.baixingnav {
  display: flex;
  justify-content: space-between;
}

.anselecte {
  width: 33%;
  text-align: center;
  height: 0.22rem;
  line-height: 0.22rem;
  display: inline-block;
  font-size: 0.12rem;
  cursor: pointer;
  color: #838181;
}

.anselecte:nth-child(1) {
  background-color: #7ea7e2;
  border-radius: 0.11rem 0rem 0rem 0.11rem;
  color: #000000;
}
.anselecte:nth-child(2) {
  /* background:red; */
  border-left: 0.01rem solid #838181;
  border-right: 0.01rem solid #838181;
}
.anselecte:nth-child(3) {
  /* background:red; */
  border-radius: 0rem 0.11rem 0.11rem 0rem;
}

.baixingnav > div:nth-child(2) {
  display: flex;
  justify-content: space-around;
  width: 1.05rem;
  height: 0.22rem;
  border: 0.01rem solid #838181;
  border-radius: 0.11rem;
}

#ancent1 {
  width: 5.8rem;
  height: 0.3rem;
  position: absolute;
  left: 0.04rem;
  top: 0.6rem;
  border: 0.01rem solid #eeb067;
  border-radius: 0.15rem;
  z-index: 10;
}
#jianoption1 {
  width: 5.2rem;
  height: 3rem;
  margin-top: 0.1rem;
}
</style>
